@import "../../styles/_chunk";

.FlashDealsSection {
  background-color: $black;
  margin-top: 3rem;
  margin-bottom: 2.5rem;
  padding: 2rem 0.5rem 0.5rem 0.5rem;
  border-radius: $border-radius;

  @include media-breakpoint-up(sm) {
    padding: 2rem 1rem 1rem 1rem;
  }

  @include media-breakpoint-up(lg) {
    padding: 2rem;
  }

  @include media-breakpoint-up(xl) {
    padding: 2.5rem 2rem;
  }

  @include media-breakpoint-up(xxl) {
    padding: 3rem 2rem;
  }

  &.no-padding {
    padding: 0;
    background-color: transparent;
  }

  .flash-deals-title {
    margin-bottom: 1rem;
  }

  .flash-deal-next-fluid-bg {
    height: 100%;
    border-radius: $border-radius;
  }

  .flash-deal-next-deal {
    color: $black;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border-radius: $border-radius;
    min-height: 100px;
    padding-top: 0.5rem;

    @include media-breakpoint-up(sm) {
      padding: 1.5rem 1rem;
    }

    @include media-breakpoint-up(md) {
      min-height: 0;
    }

    .next-deal-text {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 16px;
      color: $white;
      z-index: 2;
      text-align: center;
      line-height: 1;

      @include media-breakpoint-only(md) {
        font-size: 13px;
      }

      @include media-breakpoint-up(md) {
        font-size: 24px;
      }

      @include media-breakpoint-up(lg) {
        line-height: 0;
      }
    }

    .next-deal-timer-container {
      position: relative;
      z-index: 1;
      text-align: center;

      @include media-breakpoint-up(lg) {
        padding: 0.25rem 0.5rem;
      }

      @include media-breakpoint-up(xl) {
        padding: 0.375rem 1rem;
      }

      .sd-timer {
        text-transform: uppercase;
        color: $white;
        font-weight: 700;
        font-size: 26px;

        b {
          font-weight: inherit;
        }

        @include media-breakpoint-up(md) {
          font-size: 40px;
        }

        span {
          font-size: 16px;
          margin-right: 1rem;

          @include media-breakpoint-up(lg) {
            font-size: 18px;
          }

          @include media-breakpoint-up(xl) {
            font-size: 20px;
          }

          @include media-breakpoint-up(xxl) {
            font-size: 22px;
          }

          &:last-of-type {
            margin: 0;
          }

          &.colon {
            display: none;
          }
        }
      }
    }

    .spring-sale-flowers {
      position: absolute;
      height: 100%;
      width: 100%;
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
  }

  .product-grid {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }

    @include media-breakpoint-up(md) {
      margin-left: -0.75rem;
      margin-right: -0.75rem;
    }

    @include media-breakpoint-up(xl) {
      margin-left: -0.875rem;
      margin-right: -0.875rem;
    }

    @include media-breakpoint-up(xxl) {
      margin-left: -1rem;
      margin-right: -1rem;
    }

    .product-grid-col {
      width: 100%;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      margin-bottom: 1.25rem;
      flex-grow: 1;

      @include media-breakpoint-up(sm) {
        width: 50%;
      }

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        width: 33.333%;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
      }

      @include media-breakpoint-up(xl) {
        padding-left: 0.875rem;
        padding-right: 0.875rem;
      }

      @include media-breakpoint-up(xxl) {
        padding-left: 1rem;
        padding-right: 1rem;
      }

      .HitCardContainer,
      .fake-card-container {
        padding: 0;

        .HitCard,
        .fake-hit-card {
          margin-bottom: 0;
        }
      }
    }
  }
}

.FlashDealsSection__threeTemplate {
  .section-heading-container-between {
    padding: 0.5rem 1rem;
    background-size: cover;
    margin-bottom: 1rem;
  }

  .section-heading-timer {
    .timer-title {
      text-transform: uppercase;
      font-weight: 700;
    }
  }
}
