@import "../../styles/_chunk";

.SaleButton__button {
  font-weight: 700 !important;
  text-transform: uppercase;
  font-size: 15px !important;
  padding: 0.625rem 0.875rem !important;

  @include media-breakpoint-up(md) {
    font-size: 18px !important;
    padding: 0.875rem 1.125rem !important;
  }

  @include media-breakpoint-up(lg) {
    font-size: 20px !important;
  }
}
