@import '../../styles/_chunk';

.wishlist-most-wanted-section {
  background-color: map-get($dark-theme, secondary-background-color);
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 3rem;
  margin-top: 2rem;

  @include theme(light) {
    background-color: map-get($light-theme, secondary-background-color);
  }

  .wishlist-heading-container {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    .title-container {
      display: flex;
      align-items: center;
      font-size: 22px;
      font-weight: 400;

      @include media-breakpoint-up(sm) {
        font-size: 24px;
        justify-content: flex-start;
      }

      @include media-breakpoint-up(md) {
        font-size: 26px;
      }

      @include media-breakpoint-up(lg) {
        justify-content: center;
      }

      .icon-container {
        background-color: $white;
        border-radius: 50%;
        width: 34px;
        height: 34px;
        margin-right: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $primary-core;
        font-size: 20px;
        flex-shrink: 0;

        @include media-breakpoint-up(md) {
          margin-right: 0.75rem;
        }
      }
    }

    .button-container {
      display: flex;
      flex-shrink: 0;

      .view-all-btn {
        margin-left: 1rem;
      }
    }
  }

  .message-container {
    margin-top: 3rem;
    text-align: center;
    font-size: 16px;

    strong {
      font-weight: 700;
    }

    a {
      font-weight: 700;
    }
  }

  &.no-background {
    background-color: transparent;
    padding: 0;
    margin-bottom: 4rem;
    margin-top: 4rem;
  }

  &.is-control {
    margin-top: 2rem;
  }
}
